import React, { Component } from "react";
class VipMembership extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render(){
        return(
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <h1 style={{ textAlign: "center" }}>
                  VIP Membership
                </h1>
            </div>
        )
    }

}
export default VipMembership