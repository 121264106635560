// library imports
import { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";

// project imports
import { FeaturesRouter } from './features/routers/FeaturesRouter';
import AutoReload from '../src/features/components/auto-reload/AutoReload';
import { initFbPixel, trackPageView } from 'shared/settings/fbPixelConfig';

// style imports
import './App.scss';

export class App extends Component {

    // constructor(props) {
    //     super(props);
    //     console.log(props);
    // }

    componentDidMount() {
        // this.props.history.listen((location, action) => {
        //     console.log(location, action);
        // });
        initFbPixel();
        trackPageView();
    }

    render() {
        return (
            <div data-testid="app-comp" className="w-100 h-100">
                <ToastContainer />
                <Router>
                    <Route component={FeaturesRouter} />
                </Router>
                <AutoReload url="/index.html" forceReload />
            </div>
        );
    }
}

// export default withRouter(App);